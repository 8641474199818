export default {
    methods: {
        calculNum(num1=0, num2=0) {
            var sq1, sq2, m;
            try {
                sq1 = num1.toString().split(".")[1].length;
            }
            catch (e) {
                sq1 = 0;
            }
            try {
                sq2 = num2.toString().split(".")[1].length;
            }
            catch (e) {
                sq2 = 0;
            }
            m = Math.pow(10, Math.max(sq1, sq2));
            return (Math.round(num1 * m) - Math.round(num2 * m)) / m;
        }
    },
}