<template>
    <div class="ele-container ele-order-submit">
        <Header backType="none" :back="true" @goBack="goBack"></Header>
        <div class="content">
            <div class="smart-round-head">
                <div class="round-title">{{ eleShop.restaurantName }}</div>
                <div class="round-sub-title">
                    注意：具体送达时间请联系商家为准！
                    <br />
                    <span v-if="['pfizer'].includes(eventData.tenant_code)">
                        外部参会人数最大为20人
                    </span>
                </div>
            </div>
            <div
                class="address-card"
                v-if="eleAddress.addressId"
                @click="goMap"
            >
                <div class="all-address">
                    <div>
                        <p>
                            {{ eleAddress.addressName }}
                            {{ eleAddress.pickupLocation }}
                        </p>
                        <p>
                            <span>
                                {{ eleAddress.receiverName }}
                                ({{ eleAddress.sex ? "女士" : "先生" }})
                            </span>
                            {{ eleAddress.receiverTel }}
                        </p>
                    </div>
                    <van-icon name="arrow" />
                    <!-- <Svgs color="#90939A" name="iconiconfontleft"></Svgs> -->
                </div>
            </div>
            <template v-else>
                <van-field
                    @click="goMap"
                    disabled
                    placeholder="请选择收货地址"
                    right-icon="arrow"
                    label="收货地址"
                />
            </template>
            <van-field
                v-model="timeValue"
                class="time-class"
                @click="openTimer"
                placeholder="请选择要求送餐时间"
                disabled
                right-icon="arrow"
                label="要求送餐时间"
            />
            <template v-if="['pfizer'].includes(eventData.tenant_code)">
                <van-field
                    v-model="formData.outPeople"
                    type="number"
                    placeholder="请输入外部人数"
                    right-icon="arrow"
                    label="外部人数"
                />
                <van-field
                    v-model="formData.innerPeople"
                    type="number"
                    placeholder="请输入内部人数"
                    right-icon="arrow"
                    label="内部人数"
                />
            </template>
            <template v-else-if="['ipsen'].includes(eventData.tenant_code)&&
                ['医院科室会'].includes(eventData.typeDictTxt)">
                <div class="normalInputRow" @click="openHCP">
                    <van-field
                        v-model="formData.qty"
                        type="number"
                        placeholder="请选择参会人"
                        right-icon="arrow"
                        disabled
                        label="选择参会人"
                    />
                </div>
            </template>
            <template v-else>
                <van-field
                    v-model="formData.qty"
                    type="number"
                    placeholder="请输入用餐人数"
                    right-icon="arrow"
                    label="实际用餐人数"
                />
            </template>
            <template v-if="['novartis'].includes(eventData.tenant_code)">
                <van-field
                    v-model="formData.orderType"
                    @click="openPicker('orderType')"
                    placeholder="请选择订单类型"
                    disabled
                    right-icon="arrow"
                    label="订单类型"
                />
                <van-field
                    v-model="formData.mealType"
                    @click="openPicker('mealType')"
                    placeholder="请选择用餐类型"
                    disabled
                    right-icon="arrow"
                    label="用餐类型"
                />
            </template>
            <van-field
                v-model="formData.description"
                placeholder="请输入备注"
                right-icon="arrow"
                label="备注"
            />
            <van-divider />
            <ul class="food-list">
                <li v-for="(food, index) in eleCart.group || []" :key="index">
                    <div class="food-name">
                        <div>{{ food.foodName }}</div>
                        <div>
                            <span
                                v-for="(item, index) in food.newSpecs"
                                :key="'newSpecs' + index"
                            >
                                {{ item.value }}
                            </span>
                            <span
                                v-for="(item, index) in food.attrs"
                                :key="'attrs' + index"
                            >
                                {{ item.value }}
                            </span>
                        </div>
                    </div>
                    <div class="food-quantity">x{{ food.quantity }}</div>
                    <div class="food-price">
                        ¥{{
                            food.discountPrice ||
                            ((food.price || 0) * food.quantity).toFixed(2)
                        }}
                        <!-- <span v-if="food.discountPrice">
                            ¥{{
                                ((food.price || 0) * food.quantity).toFixed(2)
                            }}
                        </span> -->
                    </div>
                </li>
                <template v-for="(item, index) in eleCart.extra || []">
                    <li
                        v-if="item.categoryId !== 11"
                        :key="'extra' + index"
                        class="other-price-item"
                    >
                        <div>{{ item.name }}</div>
                        <div>
                            ¥{{ (item.price || 0) * (item.quantity || 0) }}
                        </div>
                    </li>
                </template>
            </ul>
            <div class="all-mount-row">
                已优惠<span
                    >¥{{
                        calculNum(eleCart.originalTotal, eleCart.total)
                    }}</span
                >
                小计¥<span>{{ eleCart.total }}</span>
            </div>
            <van-divider />
            <van-field
                v-model="invoice.name"
                disabled
                right-icon="arrow"
                label="发票抬头"
            />
            <van-field
                v-model="invoice.taxPayerCode"
                disabled
                right-icon="arrow"
                label="发票税号"
            />
            <div class="height92"></div>
            <TimePicker ref="timer" @onOk="timeBack" :timeList="timeList" />
            <Picker ref="pickers" @chooseType="chooseType" />
        </div>
        <div class="commFooter">
            <div class="commPrice">
                <p class="commCarPrice">
                    <span>总金额 ¥{{ eleCart.total }}</span>
                </p>
                <p class="commCarDes">
                    已优惠¥{{ calculNum(eleCart.originalTotal, eleCart.total) }}
                </p>
            </div>
            <div
                class="triangle"
                v-if="!disabled"
                :class="'triangle-' + iStorage.get('tenant_code')"
            ></div>
            <div v-else class="triangle triangle-disable"></div>
            <div
                @click="openMsg"
                :class="disabled ? 'commOkBtnDis' : 'commOkBtn'"
            >
                提交订单
            </div>
        </div>
    </div>
</template>

<script>
import { sumintOrder } from "@/utils/js/eleConfig.js";
import { miceService } from "@/service/miceService.js";
import { SmartStorage } from 'smart-core-util';
import { mapMutations, mapGetters } from "vuex";
import Picker from "@/components/ele/Picker.vue";
import calculNum from "@/utils/js/calculNum.js";
export default {
    name: "OrderSubmit",
    components: { Picker },
    mixins: [calculNum],
    data() {
        return {
            attenderTotal:0,
            formData: {
                qty: undefined,
                outPeople: undefined,
                innerPeople: undefined,
                orderType: "",
                mealType: "",
                description: "",
            },
            timeList: [],
            index: 0,
            time: {},
            timeValue: "",
            invoice: {},
            refresh: true,
            type: "",
            disabled: false,
        };
    },
    computed: {
        ...mapGetters([
            "eleAddress",
            "eleShop",
            "eleCart",
            "eleLocalAdd",
            "eventData",
            "shopDetail",
        ]),
        validator: {
            get: function () {
                var tenant_code = this.eventData.tenant_code;
                return sumintOrder[tenant_code];
            },
        },
    },
    activated() {
        if (this.refresh) {
            this.init();
            this.getBookTime();
            this.getCompanyInvoice();
            if(["ipsen"].includes(this.eventData.tenant_code)){
                ['医院科室会'].includes(this.eventData.typeDictTxt)?this.syncAttenders():this.QueryMiceAttenders()
            }
        }      
    },
    methods: {
        openTimer() {
            if (this.disabled) {
                return;
            }
            this.$refs.timer.open();
        },
        init() {
            this.formData = {
                qty: undefined,
                outPeople: undefined,
                innerPeople: undefined,
                orderType: "",
                mealType: "",
                description: "",
            };
            this.timeList = [];
            this.index = 0;
            this.time = {};
            this.timeValue = "";
        },
        timeBack(index, time) {
            this.index = index;
            this.time = time;
            this.timeValue = `${this.timeList[index].tab} ${time.time}`;
        },
        openPicker(type) {
            this.type = type;
            this.$refs.pickers.open(type);
        },
        chooseType(value) {
            if (this.type == "orderType") {
                this.formData.orderType = value;
            } else if (this.type == "mealType") {
                this.formData.mealType = value;
            }
        },
        getBookTime() {
            let params = {
                erestaurantId: this.eleShop.erestaurantId,
                ProposalId: this.eventData.proposalId,
                SourceCode: this.shopDetail.sourceCode,
                RegularAddr: this.shopDetail.regularAddr,
                dishes: this.eleCart.group || [],
            };
            miceService.getBookTime(params).then((res) => {
                if (res && res.success) {
                    this.disabled = false;
                    if ((res.content.bookTimeList || []).length > 0) {
                        this.timeList = res.content.bookTimeList || [];
                        this.time = (res.content.bookTimeList || [
                            { timePoints: [{}] },
                        ])[0].timePoints[0];
                        this.timeValue = `${this.timeList[0].tab} ${this.time.time}`;
                    } else {
                        this.timeValue = `立即送出（约${this.moment(
                            new Date().getTime() + 2700000
                        ).format("HH:mm")}送达）`;
                    }
                } else {
                    this.disabled = true;
                }
            });
        },
        openMsg() {
            if (this.disabled) {
                return;
            }
            if (["ipsen"].includes(this.eventData.tenant_code)&&!['医院科室会'].includes(this.eventData.typeDictTxt)&&this.formData.qty>this.attenderTotal) {
                this.$dialog
                    .confirm({
                        title: "信息提示",
                        message: `实际人数不可大于参会人数`,
                        confirmButtonText: "我知道了",
                        showCancelButton: false,
                    })
                    .then(async (action) => {
                        return;
                    });
                return;
            }
            let { typeDictTxt, tenant_code, proposalId } = this.eventData;
            let params = {
                ...this.formData,
                addressId: this.eleAddress.addressId, // 地址id
                ProposalId: proposalId,
                shopName: this.eleShop.restaurantName, // 餐厅名
                shopImg: this.eleShop.imageUrl, // 餐厅图片
                erestaurantId: this.eleShop.erestaurantId, // 餐厅id
                cartId: this.eleCart.id, // 购物车id
                total: this.eleCart.total, // 总金额
            };

            if (["pfizer"].includes(tenant_code)) {
                let qty =
                    Number(this.formData.outPeople || 0) +
                    Number(this.formData.innerPeople || 0);
                params.qty = qty;
            }
            params.typeDictTxt = typeDictTxt;
            const validateResult = this.validate(params, this.validator);
            if (!validateResult) return;
            delete params.typeDictTxt;
            let flag = false;
            // if (
            //     ["pfizer"].includes(tenant_code) &&
            //     ["同院会"].includes(typeDictTxt)
            // ) {
            //     let outPeopleNum =
            //         Number(this.formData.outPeople || 0) <= 20
            //             ? Number(this.formData.outPeople || 0)
            //             : 20;
            //     let total =
            //         (outPeopleNum + Number(this.formData.innerPeople || 0)) *
            //         100;
            //     flag =
            //         this.eleCart.total > total ||
            //         this.eleCart.total > this.iStorage.get("amount")
            //             ? true
            //             : false;
            // }
            if (flag) {
                this.$dialog
                    .confirm({
                        title: "信息提示",
                        message: `公司政策最多允许提前预定RSVP邮件中确认参会HCP人数120%人份的餐，但无论如何，实际发生餐费不得超过20个HCP人份的外卖餐费，请注意`,
                        confirmButtonText: "我知道了",
                        showCancelButton: false,
                    })
                    .then(async (action) => {
                        return;
                    });
            } else {
                params.deliverTime = this.time.deliveryTime;
                if (
                    this.eleAddress.addressName != this.eleLocalAdd.addressName && ["pfizer"].includes(this.eventData.tenant_code)
                ) {
                    this.$dialog
                        .confirm({
                            title: "信息提示",
                            message: `您当前的送餐地址与下单前选择的医院地址不一致，请确认。`,
                        })
                        .then(async (action) => {
                            if (action === "confirm") {
                                this.sureOrder(params);
                            }
                        })
                        .catch(() => {});
                } else {
                    this.sureOrder(params);
                }
            }
        },
        sureOrder(params) {
            this.$dialog
                .confirm({
                    title: "信息提示",
                    message: `是否确认下单?`,
                })
                .then(async (action) => {
                    if (action === "confirm") {
                        if (this.eventData.itemId) {
                            params.sourceCode = this.shopDetail.sourceCode;
                            params.tpOrderId = this.eventData.itemId;
                            this.saveTakeOutItem(params);
                        } else {
                            this.saveEleOrder(params);
                        }
                    }
                })
                .catch(() => {});
        },
        saveEleOrder(params) {
            let params1 = {
                amount: params.total,
                qty1: params.qty,
                sourceCode: this.shopDetail.sourceCode,
                itemName:
                    this.shopDetail.sourceCode == "ELE"
                        ? "饿了么外卖"
                        : "美餐外卖",
                proposalId: params.ProposalId,
            };
            miceService.saveTakeOutItem(params1).then((res) => {
                if (res && res.success) {
                    params.sourceCode = this.shopDetail.sourceCode;
                    params.tpOrderId = res.content;
                    this.saveTakeOutItem(params);
                }
            });
        },
        saveTakeOutItem(params) {
            miceService.saveEleOrder(params).then((res) => {
                if(!res.success){
                    this.$dialog
                    .confirm({
                        title: "提示",
                        message: res.msg,
                        showCancelButton: false,
                    })
                }
                if (res && res.success) {
                    if (this.eventData.isWeb) {
                        window.location.href = SmartStorage.get('Uris').Uris['webapp-imeal']
                    }
                    if(this.$cookies.get('tenant') == 'crossevent'){
                        this.$router.push('/ele/success?information=' + this.eleShop.restaurantName)
                    } else {
                        this.iJsBridge.setResult({});
                        this.iJsBridge.close();
                    }
                }
            });
        },
        getCompanyInvoice() {
            let params = {
                ProposalId: this.eventData.proposalId,
            };
            miceService.getCompanyInvoice(params).then((res) => {
                if (res.success) {
                    this.invoice = res.content || {};
                }
            });
        },
        goMap() {
            this.refresh = false;
            this.$router.push(
                `/ele/address?submit=true&sourceCode=${this.shopDetail.sourceCode}&erestaurantId=${this.eleShop.erestaurantId}`
            );
        },
        goBack() {
            this.refresh = true;
            this.$router.back();
        },
        openHCP() {
            this.$AttenderEditor.render({
                isEdit: true,
                miceId: this.eventData.proposalId,
                itemId: this.eventData.itemId,
                city: this.eventData.cityDictVal || "",
                typeDictVal: this.eventData.typeDictTxt,
                sourceType: "meal",
                onHandle: (response) => {
                    this.syncAttenders();
                },
            });
        },
        syncAttenders() {
            let params = {
                ProposalId: this.eventData.proposalId,
                ItemId: this.eventData.itemId,
            };
            miceService.syncAttenders(params).then((res) => {
                if (res && res.success) {
                    this.formData = {
                        ...this.formData,
                        qty: res.content.total,
                    };
                }
            });
        },
        QueryMiceAttenders(){
            let params = {
                miceId: this.eventData.proposalId,
                sourceType: "event",
            };
            miceService.QueryMiceAttenders(params).then((res) => {
                if (res && res.success) {
                    this.formData = {
                        ...this.formData,
                        qty: res.content.total,
                    };
                    this.attenderTotal=res.content.total;
                }
            });
        }
    },
};
</script>

<style lang="scss">
@import "OrderSubmit";
.attender_common_box .mint-tab-item.is-selected{
  border-bottom: 3px solid var(--themeColor)!important;
    color: var(--themeColor)!important;
}
</style>