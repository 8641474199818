<template>
    <div>
        <van-popup v-model="show" position="bottom" :style="{ maxHeight: '40%' }">
            <van-picker show-toolbar :title="title" :columns="columns" @cancel="onCancel" @confirm="onConfirm" />
        </van-popup>
    </div>
</template>
<script>
export default {
    data() {
        return {
            show: false,
            columns: [],
            title: ''
        };
    },
    methods: {
        open(type) {
            this.show = true;
            if (type == "orderType") {
                this.title = '请选择订单类型'
                this.columns = ["外卖用餐", "外卖茶歇"];
            } else if (type == "mealType") {
                this.title = '请选择用餐类型'
                this.columns = ["午餐", "晚餐"];
            }
        },
        onCancel() {
            this.show = false;
        },
        onConfirm(value) {
            this.$emit('chooseType', value)
            this.show = false;
        }
    },
    // created() {
    //     this.$bus.$on("handleShowPicker", type => {
    //         this.show = true;
    //         if (type == "type") {
    //             this.title = '请选择用餐类型'
    //             this.columns = ["外卖茶歇", "外卖用餐"];
    //         } else if (type == "category") {
    //             this.title = '请选择用餐分类'
    //             this.columns = ["外部招待", "内部招待"];
    //         }
    //     });
    // }
};
</script>
<style lang="scss" scoped>
</style>