// 饿了么外卖添加或编辑地址
export const eleAddressVal = {
    'receiverName': {
        required: true,
        description: '请填写联系人',
    }, 
    'receiverTel': {
        required: true,
        description: '请填写手机号',
        regExp: /^[1][0-9]{10}$/,
        regExpMsg:'请填写正确的手机号'
    },            
    'addressName': {
        required: true,
        description: '请选择收货地址',           
    },       
    'pickUpLocation': {
        required: true,
        description: '请填写门牌号'
    }
}
const novartisSumintOrder = {
    'addressId': {
        required: true,
        description: '请选择收货地址',    
    },
    'qty': {
        required: true,
        description: '请输入用餐人数', 
        regExp: /^[0-9]+$/,
        regExpMsg:'请填写正确的用餐人数'
    },
    'orderType': {
        required: true,
        description: '请选择订单类型', 
        expression: function(m){
            return (m.orderType == '外卖茶歇') && (m.total > 50 * m.qty)
        },
        expressionMsg:'外卖茶歇人均不能超50元'
    },
    'mealType': {
        required: true,
        description: '请选择用餐类型', 
        expression: function(m){
            if(m.orderType == "外卖用餐" && m.mealType == '午餐') {
                return m.total > 75 * m.qty
            } else if(m.orderType == "外卖用餐" && m.mealType == '晚餐') {
                return m.total > 300 * m.qty
            }
        },
        expressionMsg: function(m) {
            if(m.orderType == "外卖用餐" && m.mealType == '午餐' && m.total > 75 * m.qty) {
                return "外卖用餐类型为午餐，人均不能超75元"
            } else if(m.orderType == "外卖用餐" && m.mealType == '晚餐' && m.total > 300 * m.qty) {
                return "外卖用餐类型为晚餐，人均不能超300元"
            }
        }
    }
}

const dsclSumintOrder = {
    'addressId': {
        required: true,
        description: '请选择收货地址',    
    },
    'qty': {
        required: true,
        description: '请输入用餐人数', 
        regExp: /^[0-9]+$/,
        regExpMsg:'请填写正确的用餐人数',
        expression: function(m){
            return m.total > 100 * m.qty;
        },
        expressionMsg:'外卖人均不能超100元'
    },
}

const ipsenSumintOrder = {
    'addressId': {
        required: true,
        description: '请选择收货地址',    
    },
    'qty': {
        required: true,
        description: '请输入用餐人数', 
        regExp: /^[0-9]+$/,
        regExpMsg:'请填写正确的用餐人数',
        expression: function(m){
            return m.total > 100 * m.qty;
        },
        expressionMsg:'外卖人均不能超100元'
    },
}
const rocheSumintOrder = {
    'addressId': {
        required: true,
        description: '请选择收货地址',    
    },
    'qty': {
        required: true,
        description: '请输入用餐人数', 
        regExp: /^[1-9]\d*$/,
        regExpMsg:'请填写正确的用餐人数',
        expression: function(m){
            return m.total > 100 * m.qty;
        },
        expressionMsg:'外卖人均不能超100元'
    },
}

const pfizerSumintOrder = {
    'addressId': {
        required: true,
        description: '请选择收货地址',    
    },
    'outPeople': {
        required: true,
        description: '请输入外部人数', 
        regExp: /^[1-9]\d*$/,
        regExpMsg:'请填写正确的外部人数',
    },
    'innerPeople': {
        required: true,
        description: '请输入内部人数', 
        regExp: /^[1-9]\d*$/,
        regExpMsg:'请填写正确的内部人数',
        expression: function(m){
            return '同院会'!== m.typeDictTxt && m.total > 100 * m.qty;
        },
        expressionMsg:'外卖人均不能超100元'
    }
}

export const sumintOrder= {
    novartis: novartisSumintOrder,
    dscl: dsclSumintOrder,
    ipsen: ipsenSumintOrder,
    roche: rocheSumintOrder,
    pfizer: pfizerSumintOrder
}